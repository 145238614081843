import React, { Component } from 'react';
import { connect } from "react-redux";
import { Redirect, Route, Switch } from 'react-router-dom';
import User from '../containers/User/User';
import Pricing from '../containers/Pricing/Pricing';
import Dashboard from '../containers/Dashboard/Dashboard';
import News from '../containers/News/News';
import Payment from '../containers/Payment/Payment';
import GetLink from '../containers/GetLink/GetLink';
import Menu from '../containers/Menu/Menu';
import Header from '../containers/Header/Header';
import { userIsAuthenticated, userIsNotAuthenticated } from '../hoc/authentication';

class System extends Component {
    render() {
        const { systemMenuPath } = this.props;
        return (
            <div className="system-container">
                <Header />
                <Menu />
                <div className="system-list">
                    <Switch>
                        {/* <Route path="/dashboard" component={Dashboard} /> */}
                        <Route path="/system/getLink/:id?"  component={GetLink} />
                        <Route path="/system/profile" component={userIsAuthenticated(User)} />
                        <Route path="/system/support" component={Pricing} />
                        <Route path="/system/news" component={News} />
                        <Route path="/system/payment" component={Payment} />
                        <Route component={() => { return (<Redirect to={systemMenuPath} />) }} />
                    </Switch>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        systemMenuPath: state.app.systemMenuPath
    };
};

const mapDispatchToProps = dispatch => {
    return {
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(System);
