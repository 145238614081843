import React, { Component } from 'react';
import { connect } from 'react-redux';

import * as actions from "../../store/actions";
import { push } from "connected-react-router";
import './Menu.scss';
import * as supplierService from "../../services/supplierService"


class Menu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            listData: []
        }
    }
    componentDidMount() {
        this.getListData();
    }
    getListData = async () => {
        let res = await supplierService.getListSupplier();
        if (res == 0) {
            return;
        }
        else if (res.isSuccess) {
            let copyState = { ...this.state.listData };
            copyState = res.data;
            this.setState({
                listData: copyState
            })
        }
        else {
            return;
        }
    }
    selectedSupplier = (data) => {
        if (data) this.props.navigate("/system/getlink/" + data.id)
    }
    render() {
        const { processLogout } = this.props;

        let listData = this.state.listData
        return (
            <div className="app-menu navbar-menu">
                <div className="navbar-brand-box">
                    <a href="/system/getLink" className="logo logo-dark">
                        <span className="logo-sm">
                            <img src="../../assets/images/logo-sm.png" alt="" height="22" />
                        </span>
                        <span className="logo-lg">
                            <img src="../../assets/images/logo-light.png" alt="" height="17" />
                        </span>
                    </a>
                    <a href="/system/getLink" className="logo logo-light">
                        <span className="logo-sm">
                            <img src="../../assets/images/logo-sm.png" alt="" height="22" />
                        </span>
                        <span className="logo-lg">
                            <img src="../../assets/images/logo-light.png" alt="" height="17" />
                        </span>
                    </a>
                    <button type="button" className="btn btn-sm p-0 fs-20 header-item float-end btn-vertical-sm-hover"
                        id="vertical-hover">
                        <i className="ri-record-circle-line"></i>
                    </button>
                </div>

                <div id="scrollbar" className='h-100' style={{ overflow: 'scroll' }}>
                    <div className="container-fluid">

                        <div id="two-column-menu">
                        </div>
                        <ul className="navbar-nav" id="navbar-nav">
                            <li className="menu-title"><span data-key="t-menu">GETLINK</span></li>
                            {listData && listData.map((item, index) => {
                                return (
                                    <li className="nav-item" >
                                        <a className="nav-link menu-link" href={"/system/getlink/" + item.id} >
                                            <img style={{ width: '1.5rem', marginRight: '5px' }} src={'../../assets/images/logo/' + item.logo} />
                                            <span data-key="t-dashboards">{item.name}</span>
                                            <label>{item.usePoint}k</label>
                                        </a>
                                    </li>
                                )
                            })
                            }
                            <li className="menu-title"><i className="ri-more-fill"></i> <span data-key="t-pages">TÀI KHOẢN</span></li>

                            <li className="nav-item">
                                <a className="nav-link menu-link" href="/system/news">
                                    <i className="ri-pages-line"></i> <span
                                        data-key="t-authentication">Tin tức</span>
                                </a>
                            </li>

                            <li className="nav-item">
                                <a className="nav-link menu-link" href="/system/support">
                                    <i className="mdi mdi-lifebuoy"></i> <span data-key="t-pages">Hỗ trợ</span>
                                </a>
                            </li>

                            <li className="nav-item">
                                <a className="nav-link menu-link" href="/system/payment">
                                    <i className="ri-rocket-line"></i> <span data-key="t-landing">Nạp điểm</span>
                                </a>
                            </li>

                            <li className="nav-item">
                                <a className="nav-link menu-link" target='blank' href="https://www.messenger.com/t/100086764547466">
                                    <i className="ri-account-circle-line"></i> <span data-key="t-base-ui">Liên hệ</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className='designBy'>
                    <label>Design by: <a target='blank' title='Solutions World' href='https://ssw.vn'>SSW.VN</a></label>
                </div>
                <div className="sidebar-background">
                </div>
            </div>
        );
    }

}

const mapStateToProps = state => {
    return {
        isLoggedIn: state.user.isLoggedIn
    };
};

const mapDispatchToProps = dispatch => {
    return {
        navigate: (path) => dispatch(push(path)),
        processLogout: () => dispatch(actions.processLogout()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Menu);
