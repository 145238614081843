import React, { Component } from 'react';
import { connect } from 'react-redux';
import './Dashboard.scss';


const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            a:""
        }
    }
    render() {
        return (
            <div className="main-content">
                <div className="page-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div class="col-lg-12">
                                <div class="card">
                                    <div class="card-header align-items-center d-flex">
                                        <h4 class="card-title mb-0 flex-grow-1">Bảng giá tải ảnh</h4>
                                    </div>
                                    <div class="card-body">
                                        <div class="live-preview">
                                            <div class="row align-items-center g-3">
                                                <div class="col-lg-12">
                                                    data table
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
    };
};

const mapDispatchToProps = dispatch => {
    return {
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
