import React, { Component } from 'react';
import { connect } from 'react-redux';
import './Payment.scss';

import * as userService from "../../services/userService"
import * as messageService from "../../services/messageService"
import { ReactNotifications } from 'react-notifications-component'
import vietcombankhai from '../../assets/images/vietcom-hai.jpg'
import momohai from '../../assets/images/momo-hai.jpg'

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

class Payment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isEditPro: false,
            user: {
                email: "",
                name: "",
                lastName: "",
                phoneNumber: "",
                address: "",
                companyName: ""
            }
        }
    }
    componentDidMount() {
    }
    editProduct = (isEdit) => {
        this.setState({
            isEditPro: isEdit
        })
    }
    /// edit
    onChangeInput = (event, id) => {
        let copyState = { ...this.state.user };
        copyState[id] = event.target.value;
        this.setState({
            user: copyState
        })
    }
    onSubmit = async () => {
        if (!this.isValidData()) return;
        let res = await userService.update(this.state.user);
        if (res == 0) {
            messageService.messageNotify('', 'Lấy dữ liệu lỗi', 'warning');
        }
        else if (res.isSuccess) {
            messageService.messageNotify('', 'Edit success', 'success');
        }
        else {
            messageService.messageNotify('', res.message, 'warning');
        }
    }
    isValidData = () => {
        let arr = ["email", "name", "lastName", "phoneNumber", "address", "companyName"]
        for (let i = 0; i < arr.length; i++) {
            if (!this.state.user[arr[i]]) {
                messageService.messageNotify('', ('Please enter your ' + arr[i]), 'warning');
                return false;
            }
        }
        return true;
    }
    render() {
        return (
            <div className="main-content">
                <ReactNotifications />
                <div className="page-content">
                    <div className='card'>
                        <div className='card-body'>
                            <div className="container-fluid">
                                <div className="row mb-3 pb-1" style={{ marginTop: "27px" }}>
                                    <div className="col-1"></div>
                                    <div className="col-10">
                                        <div className="payment">
                                            <div className="flex-grow-1" style={{ textAlign: 'center' }}>
                                                <h1 className="fs-27 mb-1" style={{ fontWeight: 'bold', color: '#405189' }}>
                                                    HƯỚNG DẪN NẠP ĐIỂM</h1>
                                            </div>
                                            <p>
                                                Sau khi chọn được gói thanh toán phù hợp, bạn thanh toán số tiền cần nạp theo hướng dẫn:<br />
                                                <ul>
                                                    <li>Nội dung chuyển khoản là <label>MS Mail</label></li>
                                                    <li>Ví dụ: <label>MS haihc</label>, không cần phải @...</li>
                                                    <li>Ưu tiên chuyển khoản qua VIETCOMBANK, chuyển qua MOMO vui lòng đợi mình khoản 2 phút xử lý.</li>
                                                </ul>
                                            </p>
                                            <div className='row comtent'>
                                                <div className="col-1">
                                                </div>
                                                <div className="col-4 center">
                                                    <label className='name'>VIETCOMBANK</label>
                                                    <label>STK: 0111000341019</label>
                                                    <label>HUỲNH CHÍ HẢI</label>
                                                    <img src={vietcombankhai}></img>
                                                </div>
                                                <div className="col-3">
                                                </div>
                                                <div className="col-4 center">
                                                    <label className='name'>MOMO</label>
                                                    <label>SĐT: 0918625175</label>
                                                    <label>HUỲNH CHÍ HẢI</label>
                                                    <img src={momohai}></img>
                                                </div>
                                                <div className="col-1">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-1"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
    };
};

const mapDispatchToProps = dispatch => {
    return {
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Payment);
