import React, { Component } from 'react';
import { connect } from 'react-redux';
import './Pricing.scss';

import * as supplierService from "../../services/supplierService"
import * as messageService from "../../services/messageService"
import { ReactNotifications } from 'react-notifications-component'

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

class Pricing extends Component {
    constructor(props) {
        super(props);
        this.state = {
            listData: []
        }
    }
    componentDidMount() {
        this.getListData();
    }
    getListData = async () => {
        let res = await supplierService.getListSupplier();
        if (res == 0) {
            return;
        }
        else if (res.isSuccess) {
            let copyState = { ...this.state.listData };
            copyState = res.data;
            this.setState({
                listData: copyState
            })
        }
        else {
            return;
        }
    }
    render() {
        return (
            <div className="main-content">
                <ReactNotifications />
                <div className="page-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="card-header align-items-center d-flex">
                                        <h4 className="card-title mb-0 flex-grow-1">Bảng giá tải ảnh</h4>
                                    </div>
                                    <div className="card-body">
                                        <div className="live-preview">
                                            <div className="row align-items-center g-3">
                                                <div className="col-lg-12">
                                                    <table className="table table-bordered">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col">Tên</th>
                                                                <th scope="col" className="hidden-480">Trang nguồn</th>
                                                                <th scope="col" className="hidden-480">Định dạng hỗ trợ</th>
                                                                <th scope="col">Điểm</th>
                                                                <th scope="col" className="hidden-480">Giới hạn</th>
                                                                <th scope="col" className="hidden-480">Tải lại</th>
                                                                <th scope="col" className="hidden-480">Hỗ trợ sự cố</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {this.state.listData && this.state.listData.map((item, index) => {
                                                                return (
                                                                    <tr>
                                                                        <th>{item.name}<span className="badge badge-pill badge-warning">VIP</span></th>
                                                                        <td className="hidden-480"><a target='blank' href={item.website}>{item.website}</a></td>
                                                                        <td className="hidden-480">
                                                                            {item?.isAI && < img className="extension" src="../../assets/images/extension/AI.png" />}
                                                                            {item?.isAIV && < img className="extension" src="../../assets/images/extension/AVI.png" />}
                                                                            {item?.isEPS && < img className="extension" src="../../assets/images/extension/EPS.png" />}
                                                                            {item?.isJPG && < img className="extension" src="../../assets/images/extension/JPG.png" />}
                                                                            {item?.isMOV && < img className="extension" src="../../assets/images/extension/MOV.png" />}
                                                                            {item?.isMP3 && < img className="extension" src="../../assets/images/extension/MP3.png" />}
                                                                            {item?.isPNG && < img className="extension" src="../../assets/images/extension/PNG.png" />}
                                                                            {item?.isPSD && < img className="extension" src="../../assets/images/extension/PSD.png" />}
                                                                            {item?.isSVG && < img className="extension" src="../../assets/images/extension/SVG.png" />}
                                                                            {item?.isTIF && < img className="extension" src="../../assets/images/extension/TIF.png" />}
                                                                            {item?.isZIP && < img className="extension" src="../../assets/images/extension/ZIP.png" />}
                                                                        </td>
                                                                        <td><span className="badge badge-pill badge-primary">{item?.usePoint}</span></td>
                                                                        <td className="hidden-480"><span className="badge badge-pill badge-secondary">không giới hạn</span></td>
                                                                        <td className="hidden-480"><span className="badge badge-pill badge-info">24 giờ</span></td>
                                                                        <td className="hidden-480"><span className="badge badge-pill badge-success">facebook/zalo</span></td>
                                                                    </tr>
                                                                )
                                                            })
                                                            }

                                                        </tbody>
                                                    </table>
                                                </div>

                                                <div className='d-flex flex-wrap gap-2' style={{ textAlign: 'center' }}>
                                                    <a href='/system/payment' type="button" class="btn rounded-pill btn-success waves-effect waves-light  fs-17"
                                                        style={{ margin: "0 auto" }}>NẠP ĐIỂM</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
    };
};

const mapDispatchToProps = dispatch => {
    return {
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Pricing);
