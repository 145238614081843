import React, { Component } from 'react';
import { connect } from 'react-redux';
import Marquee from "react-fast-marquee";

import * as actions from "../../store/actions";
import { push } from "connected-react-router";
import * as clientService from "../../services/clientService.js"
import * as notiService from "../../services/notiService.js"
import './Header.scss';
import { Link } from 'react-router-dom';

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isShowUser: false,
            user: {
                email: "",
                name: "",
                phoneNumber: "",
            },
            notiText: ''
        }
    }
    componentDidMount() {
        this.getCurrentUser();
        this.getNoti();
    }
    getCurrentUser = async () => {
        let res = await clientService.getCurrentInfo();
        if (res == 0) {
            return;
        }
        // else if (res == 401) {
        //     setTimeout(() => {
        //         this.handlePage('/login')
        //     }, 2000);
        //     return;
        // }
        else if (res.isSuccess) {
            let copyState = { ...this.state.user };
            copyState = res.data;
            this.setState({
                user: copyState
            })
        }
        else {
            return;
        }
    }
    getNoti = async () => {
        let res = await notiService.get(1); if (res.isSuccess) {
            this.setState({
                notiText: res.data.name
            })
        }
    }
    handlePage = (path) => {
        this.props.navigate(path);
    }
    showInfoUser = () => {
        this.setState({
            isShowUser: !this.state.isShowUser
        })
    }
    render() {
        const { processLogout } = this.props;
        let aaa = this.state.notiText;

        return (
            <header id="page-topbar">
                <div className="layout-width">
                    <div className="navbar-header">
                        {/* <div className="d-flex">
                            <div className="navbar-brand-box horizontal-logo">
                            </div>
                        </div> */}
                        <div className='col-md-8'>
                            <Marquee speed='59'>
                                <h4 className="text-success">{aaa}</h4>
                            </Marquee>
                        </div>
                        <div className="d-flex align-items-center">

                            <div className="dropdown topbar-head-dropdown ms-1 header-item">
                                <button type="button" className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
                                    id="page-header-notifications-dropdown" data-bs-toggle="dropdown" aria-haspopup="true"
                                    aria-expanded="false">
                                    <i className='bx bx-bell fs-22'></i>
                                    <span
                                        className="position-absolute topbar-badge fs-10 translate-middle badge rounded-pill bg-danger">{this.state.user?.pointG ? this.state.user?.pointG : '0'}<span
                                            className="visually-hidden">Điểm</span></span>
                                </button>
                            </div>

                            <div className="dropdown ms-sm-3 header-item topbar-user">
                                <button type="button" className="btn" id="page-header-user-dropdown" data-bs-toggle="dropdown"
                                    aria-haspopup="true" aria-expanded="false">
                                    <span className="d-flex align-items-center">
                                        <img className="rounded-circle header-profile-user"
                                            src="../../assets/images/users/avatar-1.jpg" alt="Header Avatar" />
                                        <span className="text-start ms-xl-2">
                                            <span className="d-none d-xl-inline-block ms-1 fw-medium user-name-text">{this.state.user?.name ? this.state.user?.name : 'Xin chào'}</span>
                                            {/* <span
                                                    className="d-none d-xl-block ms-1 fs-12 text-muted user-name-sub-text">Founder</span> */}
                                        </span>
                                    </span>
                                </button>
                                <div className="dropdown-menu dropdown-menu-end">
                                    <h6 className="dropdown-header">Xin chào {this.state.user?.name ? this.state.user?.name : ''}!</h6>
                                    <a className="dropdown-item" href="/system/profile"><i
                                        className="mdi mdi-account-circle text-muted fs-16 align-middle me-1"></i> <span
                                            className="align-middle"
                                        >Tài khoản</span></a>
                                    <a className="dropdown-item" href="/system/profile"><i
                                        className="mdi mdi-calendar-check-outline text-muted fs-16 align-middle me-1"></i>
                                        <span className="align-middle">Lịch sử tải ảnh</span></a>
                                    <a className="dropdown-item" href="/system/profile"><i
                                        className="mdi mdi-wallet text-muted fs-16 align-middle me-1"></i> <span
                                            className="align-middle">Lịch sử thanh toán</span></a>
                                    <a className="dropdown-item" onClick={processLogout}><i
                                        className="mdi mdi-logout text-muted fs-16 align-middle me-1"></i> <span
                                            className="align-middle" data-key="t-logout">Đăng xuất</span></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        );
    }

}

const mapStateToProps = state => {
    return {
        isLoggedIn: state.user.isLoggedIn
    };
};

const mapDispatchToProps = dispatch => {
    return {
        navigate: (path) => dispatch(push(path)),
        processLogout: () => dispatch(actions.processLogout()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
