import React, { Component } from 'react';
import { connect } from 'react-redux';
import './User.scss';

import * as clientService from "../../services/clientService";
import { ReactNotifications } from 'react-notifications-component';
import DataTable from 'react-data-table-component';
import moment from 'moment/moment';
import * as supplierService from "../../services/supplierService"

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

class User extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isEditPro: false,
            user: {
                email: "",
                name: "",
                lastName: "",
                phoneNumber: "",
                address: "",
                companyName: "",
            },
            changePassModel: {
                currentPassWord: "",
                newPassWord: "",
                confirmPassWord: "",
            },
            //
            listHistoryImg: [],
            filterImg: {
                searchText: '',
                pageNum: 1,
                pageSize: 20,
            },
            totalCountImg: 0,
            //
            listHistoryPay: [],
            filterPay: {
                searchText: '',
                pageNum: 1,
                pageSize: 20,
            },
            totalCountPay: 0,
        }
    }
    componentDidMount() {
        this.getCurrentUser();
        this.getListHistoryImg();
        this.getListHistoryPay();
    }
    getCurrentUser = async () => {
        let res = await clientService.getCurrentInfo();
        if (res == 0) {
            return;
        }
        else if (res == 401) {
            setTimeout(() => {
                localStorage.removeItem("persist:user");
                window.location.href = '/login';
            }, 2000);
            return;
        }
        else if (res.isSuccess) {
            let copyState = { ...this.state.user };
            copyState = res.data;
            this.setState({
                user: copyState
            })
        }
        else {
            return;
        }
    }
    handlePage = (path) => {
        this.props.navigate(path);
    }
    getListHistoryImg = async () => {
        let res = await clientService.getDownloadHistory(this.state.filterImg);
        if (res == 0) {
            return;
        }
        else if (res.isSuccess) {
            let copyState = { ...this.state.listHistoryImg };
            copyState = res.data;
            this.setState({
                listHistoryImg: copyState,
                totalCountImg: res.data[0] ? res.data[0].totalCount : 0
            })
        }
        else {
            return;
        }
    }
    onPageChangeImg = (pageSize, pageNum) => {
        this.state.filterImg.pageNum = pageNum;
        if (pageSize != 0) this.state.filterImg.pageSize = pageSize;
        this.getListHistoryImg();
    }
    onPageChangePay = (pageSize, pageNum) => {
        this.state.filterPay.pageNum = pageNum;
        if (pageSize != 0) this.state.filterPay.pageSize = pageSize;
        this.getListHistoryPay();
    }
    getListHistoryPay = async () => {
        let res = await clientService.getAddPointHistory(this.state.filterPay);
        if (res == 0) {
            return;
        }
        else if (res.isSuccess) {
            let copyState = { ...this.state.listHistoryPay };
            copyState = res.data;
            this.setState({
                listHistoryPay: copyState,
                totalCountPay: res.data[0] ? res.data[0].totalCount : 0
            })
        }
        else {
            return;
        }
    }
    editProduct = (isEdit) => {
        this.setState({
            isEditPro: isEdit
        })
    }
    /// edit
    onChangeInput = (event, id) => {
        let copyState = { ...this.state.changePassModel };
        copyState[id] = event.target.value;
        this.setState({
            changePassModel: copyState
        })
    }
    reDowload = async (resLink) => {
        let shutterstock = resLink.downloadLug
        let id = resLink.downloadId
        let type = resLink.downloadType
        let resDownload = await supplierService.downloadStatus(shutterstock, type, id);
        if (resDownload == 0) {
            return;
        }
        else if (resDownload.isSuccess) {
            if (resDownload.data.result.status == 200) {
                if (resDownload.data.result.result.status == 1) {
                    let itemDCode = resDownload.data.result.result.itemDCode
                    let resTk = await supplierService.gettk(shutterstock, type, id);
                    if (resTk == 0) {
                        return;
                    }
                    else if (resTk.isSuccess) {
                        let linkDowload = 'https://getstocks.net/api/v1/download/' + itemDCode + '?token=' + resTk.data
                        window.open(linkDowload, "_self");
                        return false;
                    }
                    else {
                        return;
                    }
                }
                else {
                    if (this.state.count < 5) {
                        setTimeout(
                            function () {
                                this.reDowload(resLink);
                            }
                                .bind(this),
                            5000
                        );
                        this.setState({
                            count: this.state.count++
                        })

                    }
                }
            }
            else {
                if (this.state.count < 5) {
                    setTimeout(
                        function () {
                            this.reDowload(resLink);
                        }
                            .bind(this),
                        5000
                    );
                    this.setState({
                        count: this.state.count++
                    })
                }
            }
        }
        else {
            return;
        }
    }
    changePassWord = async () => {
        if (!this.isValidDataPass()) return;
        let res = await clientService.changePassWord(this.state.changePassModel);
        if (res == 0) {
        }
        else if (res.isSuccess) {
        }
        else {
        }
    }
    isValidDataPass = () => {
        if (!this.state.changePassModel.currentPassWord) {
            return false;
        }
        if (!this.state.changePassModel.newPassWord) {
            return false;
        }
        if (this.state.changePassModel.newPassWord != this.state.changePassModel.confirmPassWord) {
            return false;
        }
        return true;
    }
    showLink = (link) => {
        window.open(link, "_blank")
    }
    render() {
        let listHistoryImg = this.state.listHistoryImg
        let listHistoryPay = this.state.listHistoryPay
        const paginationComponentOptions = {
            rowsPerPageText: "Số dòng",
            rangeSeparatorText: "của"
        };
        const columnsHistoryImg = [
            {
                name: '#',
                selector: (row, index) => index + 1,
            },
            {
                name: 'Thời gian',
                selector: row => row.createdWhen,
                format: (row) => moment(row.createdWhen).format('HH:mm  DD/MM/yyyy'),
            },
            {
                name: 'Link gốc',
                selector: row => row.link,
                cell: (row) => {
                    return (
                        <div>
                            <a
                                // href={row.link}
                                style={{ fontSize: '17px' }}
                                onClick={() => this.showLink(row.link)}
                                className="shadow btn-xs sharp me-1">
                                {row.link}
                            </a>
                        </div>
                    )
                },
            },
            {
                name: 'Tải lại',
                selector: rows => rows,
                cell: (row) => {
                    return (
                        <div>
                            <a
                                onClick={() => this.reDowload(row)}
                                className="btn btn-primary shadow btn-xs sharp me-1"><i class="fas fa-download"></i></a>
                        </div>
                    )
                },
            },
        ];
        const columnsHistoryPayment = [
            {
                name: '#',
                selector: (row, index) => index + 1,
            },
            {
                name: 'Thời gian',
                selector: row => row.createdWhen,
                format: (row) => moment(row.createdWhen).format('HH:mm  DD/MM/yyyy'),
            },
            {
                name: 'Người xử lý',
                selector: row => row.userName,
            },
            {
                name: 'Điểm P',
                selector: row => row.pointP,
            },
            {
                name: 'Điểm G',
                selector: row => row.pointG,
            },
        ];
        return (
            <div className="main-content">
                <ReactNotifications />
                <div className="page-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-xxl-3">
                                <div className="card">
                                    <div className="card-body p-4">
                                        <div className="text-center">
                                            <div className="profile-user position-relative d-inline-block mx-auto  mb-4">
                                                <img src="../assets/images/users/avatar-1.jpg"
                                                    className="rounded-circle avatar-xl img-thumbnail user-profile-image"
                                                    alt="user-profile-image" />
                                                <div className="avatar-xs p-0 rounded-circle profile-photo-edit">
                                                    {/* <input id="profile-img-file-input" type="file"
                                                        className="profile-img-file-input" /> */}
                                                    <label htmlFor="profile-img-file-input"
                                                        className="profile-photo-edit avatar-xs">
                                                        <span className="avatar-title rounded-circle bg-light text-body">
                                                            <i className="ri-camera-fill"></i>
                                                        </span>
                                                    </label>
                                                </div>
                                            </div>
                                            <h5 className="fs-16 mb-1">{this.state.user?.name}</h5>
                                            <p className="text-muted mb-0">{this.state.user?.pointG ? this.state.user?.pointG : '0'} điểm G / {this.state.user?.pointP ? this.state.user?.pointP : '0'} điểm P</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xxl-9">
                                <div className="card">
                                    <div className="card-header">
                                        <ul className="nav nav-tabs-custom rounded card-header-tabs border-bottom-0"
                                            role="tablist">
                                            <li className="nav-item">
                                                <a className="nav-link active" data-bs-toggle="tab" href="#personalDetails"
                                                    role="tab">
                                                    <i className="fas fa-home"></i> Thông tin cá nhân
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link" data-bs-toggle="tab" href="#changePassword" role="tab">
                                                    <i className="far fa-user"></i> Đổi mật khẩu
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="card-body p-4">
                                        <div className="tab-content">
                                            <div className="tab-pane active" id="personalDetails" role="tabpanel">
                                                <div className="row">
                                                    <div className="col-lg-6">
                                                        <div className="mb-3">
                                                            <label htmlFor="firstnameInput" className="form-label">Họ & tên</label>
                                                            <input type="text" className="form-control" id="firstnameInput"
                                                                placeholder="" value={this.state.user?.name} disabled />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        {/* <div className="mb-3">
                                                                <label htmlFor="lastnameInput" className="form-label">Last
                                                                    Name</label>
                                                                <input type="text" className="form-control" id="lastnameInput"
                                                                    placeholder="Enter your lastname" value="Adame" />
                                                            </div> */}
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <div className="mb-3">
                                                            <label htmlFor="emailInput" className="form-label">Địa chỉ email</label>
                                                            <input type="email" className="form-control" id="emailInput"
                                                                placeholder=""
                                                                value={this.state.user?.email} disabled />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <div className="mb-3">
                                                            <label htmlFor="phonenumberInput" className="form-label">Số điện thoại</label>
                                                            <input type="text" className="form-control"
                                                                id="phonenumberInput"
                                                                placeholder=""
                                                                value={this.state.user?.phoneNumber} disabled />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="tab-pane" id="changePassword" role="tabpanel">
                                                <div className="row g-2">
                                                    <div className="col-lg-4">
                                                        <div>
                                                            <label htmlFor="oldpasswordInput" className="form-label">Mật khẩu cũ *</label>
                                                            <input
                                                                value={this.state.changePassModel.currentPassWord}
                                                                onChange={(event) => this.onChangeInput(event, "currentPassWord")}
                                                                type="password" className="form-control"
                                                                id="oldpasswordInput"
                                                                placeholder="Nhập mật khẩu hiện tại" />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4">
                                                        <div>
                                                            <label htmlFor="newpasswordInput" className="form-label">Mật khẩu mới *</label>
                                                            <input
                                                                value={this.state.changePassModel.newPassWord}
                                                                onChange={(event) => this.onChangeInput(event, "newPassWord")}
                                                                type="password" className="form-control"
                                                                id="newpasswordInput" placeholder="Nhập mật khẩu mới" />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4">
                                                        <div>
                                                            <label htmlFor="confirmpasswordInput" className="form-label">Xác nhận mật khẩu *</label>
                                                            <input
                                                                value={this.state.changePassModel.confirmPassWord}
                                                                onChange={(event) => this.onChangeInput(event, "confirmPassWord")}
                                                                type="password" className="form-control"
                                                                id="confirmpasswordInput"
                                                                placeholder="Nhập lại mật khẩu mới" />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="text-end">
                                                            <button
                                                                onClick={() => this.changePassWord()}
                                                                type="submit" className="btn btn-success">Đổi mật khẩu</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className="col-xl-12">
                                <div className="card">
                                    <div className="card-header">
                                        <ul className="nav nav-tabs-custom rounded card-header-tabs border-bottom-0"
                                            role="tablist">
                                            <li className="nav-item">
                                                <a className="nav-link active" data-bs-toggle="tab" href="#downloadHistory"
                                                    role="tab">
                                                    <i className="bx bxs-download"></i> Lịch sử tải ảnh
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link" data-bs-toggle="tab" href="#paymentHistory" role="tab">
                                                    <i className="bx bx-money"></i> Lịch sử thanh toán
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="card-body p-4">
                                        <div className="tab-content">
                                            <div className="tab-pane active" id="downloadHistory" role="tabpanel">
                                                <DataTable
                                                    className='dartTable'
                                                    lassName='dartTable'
                                                    data-tag="allowRowEvents"
                                                    columns={columnsHistoryImg}
                                                    data={listHistoryImg}
                                                    striped={true}
                                                    noDataComponent={"Không tìm thấy dữ liệu"}

                                                    pagination
                                                    paginationComponentOptions={paginationComponentOptions}
                                                    paginationServer={true}
                                                    paginationTotalRows={this.state.totalCountImg}
                                                    paginationRowsPerPageOptions={[20, 50, 100, 200]}
                                                    onChangePage={(page) => {
                                                        this.onPageChangeImg(0, page)
                                                    }}
                                                    onChangeRowsPerPage={(pageSize, pageNum) => {
                                                        this.onPageChangeImg(pageSize, pageNum)
                                                    }}
                                                />
                                            </div>
                                            <div className="tab-pane" id="paymentHistory" role="tabpanel">
                                                <DataTable
                                                    className='dartTable'
                                                    lassName='dartTable'
                                                    data-tag="allowRowEvents"
                                                    columns={columnsHistoryPayment}
                                                    data={listHistoryPay}
                                                    striped={true}
                                                    noDataComponent={"Không tìm thấy dữ liệu"}

                                                    pagination
                                                    paginationComponentOptions={paginationComponentOptions}
                                                    paginationServer={true}
                                                    paginationTotalRows={this.state.totalCountPay}
                                                    paginationRowsPerPageOptions={[20, 50, 100, 200]}
                                                    onChangePage={(page) => {
                                                        this.onPageChangePay(0, page)
                                                    }}
                                                    onChangeRowsPerPage={(pageSize, pageNum) => {
                                                        this.onPageChangePay(pageSize, pageNum)
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
    };
};

const mapDispatchToProps = dispatch => {
    return {
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(User);
