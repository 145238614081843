import React, { Component } from 'react';
import { connect } from 'react-redux';
import './News.scss';

import { ReactNotifications } from 'react-notifications-component'
import * as newService from "../../services/newService";
import * as constant from '../../utils/constant'
import { format } from 'date-fns'
const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

class News extends Component {
    constructor(props) {
        super(props);
        this.state = {
            listData: [],
            filterNew: {
                searchText: "",
                isPromotion: false,
                pageSize: 200,
                pageNum: 1
            },
        }
    }
    componentDidMount() {
        this.getNews();
    }
    getNews = async () => {
        let res = await newService.getListNew(this.state.filterNew);
        if (res == 0) {
            return;
        }
        else if (res.isSuccess) {
            let copyState = { ...this.state.listData };
            copyState = res.data;
            this.setState({
                listData: copyState,
                totalCount: res.data[0] ? res.data[0].totalCount : 0
            })
        }
        else {
            return;
        }
    }
    render() {
        let listData = this.state.listData
        let path = constant.url.urlImg;
        return (
            <div className="main-content">
                <ReactNotifications />
                <div className="page-content">
                    <div className="container-fluid">
                        {listData && listData.map((item, index) => {
                            return (

                                <div className="row">
                                    <div class="col-lg-12">
                                        <div class="card">
                                            <div class="card-header align-items-center d-flex">
                                                <h4 class="card-title mb-0 flex-grow-1" style={{ color: '#6883de' }}>{item.name}</h4>
                                            </div>
                                            <div class="card-body">
                                                <div class="live-preview">
                                                    <div class="row">
                                                        <div class="col-lg-3" style={{ textAlign: 'center' }}>
                                                            <img style={{ height: '177px' }} src={path + item.imageUrl}
                                                                className="img-thumbnail user-profile-image"
                                                                alt="user-profile-image" />
                                                        </div>
                                                        <div class="col-lg-9">
                                                            <p>{item.description}</p>
                                                        </div>
                                                        <div class="dropdown-divider"></div>
                                                        <div class="col-lg-12">
                                                            <p style={{ textAlign: 'right' }} class="text-uppercase fw-medium text-muted text-truncate mb-0">
                                                                {format(new Date(item.createdWhen), 'yyyy/MM/dd kk:mm:ss')}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                        }
                    </div>
                </div>
            </div >
        )
    }
}

const mapStateToProps = state => {
    return {
    };
};

const mapDispatchToProps = dispatch => {
    return {
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(News);
