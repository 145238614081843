import axios from '../axios';


export const getListNew = async (filterModel) => {
    try {
        var res = await axios.get(`/News/GetListNews`, {
            params: {
                isPromotion: filterModel.isPromotion,
                searchText: filterModel.searchText,
                pageNum: filterModel.pageNum,
                pageSize: filterModel.pageSize,
            }
        })
        return res;
    } catch (error) {
        return 0;
    }
};
