import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from "connected-react-router";
import { ReactNotifications } from 'react-notifications-component'

import './Register.scss';

import * as clientService from "../../services/clientService.js"

import * as messageService from "../../services/messageService.js"
import * as valid from '../../utils/validate'

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

class Register extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isShowPw: false,
            createModel: {
                name: "",
                email: "",
                password: ""
            },
            isShowRegis: true,
        }
    }

    handlePage = (path) => {
        this.props.navigate(path);
    }

    showHidePw = () => {
        this.setState({
            isShowPw: !this.state.isShowPw
        })
    }
    onChangeInput = (event, id) => {
        let copyState = { ...this.state.createModel };
        copyState[id] = event.target.value;
        this.setState({
            createModel: copyState
        })
    }
    handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            this.onSubmit();
        }
    }
    onSubmit = async () => {
        this.setState({
            isShowRegis: false
        })
        if (!this.isValidData()) {
            this.setState({
                isShowRegis: true
            })
            return;
        };
        let res = await clientService.create(this.state.createModel);
        if (res == 0) {
            this.setState({
                isShowRegis: true
            })
            messageService.messageNotify('', 'Lỗi đăng ký', 'warning');
            return;
        }
        else if (res.isSuccess) {
            messageService.messageNotify('', 'Đăng kí thành công', 'success');
            this.handlePage('/login');
        }
        else {
            this.setState({
                isShowRegis: true
            })
            messageService.messageNotify('', 'Lỗi đăng ký', 'warning');
            return;
        }
    }
    isValidData = () => {
        if (!this.state.createModel.name) {
            messageService.messageNotify('', 'Vui lòng nhập họ & tên', 'warning');
            return false;
        }
        if (!this.state.createModel.email) {
            messageService.messageNotify('', 'Vui lòng nhập email', 'warning');
            return false;
        }
        if (!valid.validateEmail(this.state.createModel.email)) {
            messageService.messageNotify('', 'Email không hợp lệ', 'warning');
            return false;
        }
        if (!this.state.createModel.password) {
            messageService.messageNotify('', 'Vui lòng nhập mật khẩu', 'warning');
            return false;
        }
        return true;
    }
    render() {
        return (
            <>
                <ReactNotifications />
                <div className="auth-page-wrapper pt-5">
                    <div className="auth-one-bg-position auth-one-bg" id="auth-particles">
                        <div className="bg-overlay"></div>

                        <div className="shape">
                            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 1440 120">
                                <path d="M 0,36 C 144,53.6 432,123.2 720,124 C 1008,124.8 1296,56.8 1440,40L1440 140L0 140z"></path>
                            </svg>
                        </div>
                    </div>

                    <div className="auth-page-content">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="text-center mt-sm-5 mb-4 text-white-50">
                                        <div>
                                            <a href="index.html" className="d-inline-block auth-logo">
                                                <img src="assets/images/logo-light.png" alt="" height="20" />
                                            </a>
                                        </div>
                                        {/* <p className="mt-3 fs-15 fw-medium">Premium Admin & Dashboard Template</p> */}
                                    </div>
                                </div>
                            </div>

                            <div className="row justify-content-center">
                                <div className="col-md-8 col-lg-6 col-xl-5">
                                    <div className="card mt-4">

                                        <div className="card-body p-4">
                                            <div className="text-center mt-2">
                                                <h5 className="text-primary">Đăng ký mới tài khoản muaStocks.com</h5>
                                            </div>
                                            <div className="p-2 mt-4">
                                                <div className="mb-3">
                                                    <label className="form-label">Họ & Tên <span className="text-danger">*</span></label>
                                                    <input
                                                        onKeyPress={this.handleKeyPress}
                                                        value={this.state.createModel.name}
                                                        onChange={(event) => this.onChangeInput(event, 'name')}
                                                        type="text" className="form-control" id="name" placeholder="Nhập ho & tên" />
                                                </div>

                                                <div className="mb-3">
                                                    <label className="form-label">Email <span className="text-danger">*</span></label>
                                                    <input
                                                        onKeyPress={this.handleKeyPress}
                                                        value={this.state.createModel.email}
                                                        onChange={(event) => this.onChangeInput(event, 'email')}
                                                        type="email" className="form-control" id="useremail" placeholder="Nhập email" />
                                                </div>

                                                <div className="mb-3">
                                                    <label className="form-label" for="password-input">Mật khẩu <span className="text-danger">*</span></label>
                                                    <div className="position-relative auth-pass-inputgroup">
                                                        <input
                                                            onKeyPress={this.handleKeyPress}
                                                            value={this.state.createModel.password}
                                                            onChange={(event) => this.onChangeInput(event, 'password')}
                                                            type="password" className="form-control pe-5 password-input" onpaste="return false" placeholder="Nhập mật khẩu" id="password-input" aria-describedby="passwordInput" pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}" required />
                                                        <button onClick={() => this.showHidePw()} className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted password-addon" type="button" id="password-addon">
                                                            {/* <i className="ri-eye-fill align-middle"></i> */}
                                                            <i className={!this.state.isShowPw ? 'fa-eye fas fa-eye-slash align-middle' : 'far fa-eye align-middle'}></i>
                                                        </button>
                                                    </div>
                                                </div>
                                                {this.state.isShowRegis && <div className="mt-4">
                                                    <button
                                                        onClick={() => this.onSubmit()}
                                                        className="btn btn-success w-100" type="submit">Đăng ký</button>
                                                </div>}

                                            </div>
                                        </div>
                                    </div>

                                    <div className="mt-4 text-center">
                                        <p className="mb-0">Bạn đã có tài khoản ? <a onClick={() => this.handlePage('/login')} className="fw-semibold text-primary text-decoration-underline"> Đăng nhập </a> </p>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                    <footer className="footer">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="text-center">
                                        <p className="mb-0 text-muted">&copy; 2022 muastocks.com
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </footer>
                </div>
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        language: state.app.language
    };
};

const mapDispatchToProps = dispatch => {
    return {
        navigate: (path) => dispatch(push(path)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Register);
