import { Store } from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import * as constant from '../../src/utils/constant'

export const messageNotify = async (title, content, type) => {
  Store.addNotification({
    title: title,
    message: content,
    type: type,
    insert: "top",
    container: constant.notify.container,
    dismiss: {
      duration: constant.notify.duration,
    }
  });
};
