import axios from '../axios';


export const getListSupplier = async () => {
    try {
        var res = await axios.get(`/Supplier/GetListSupplier`)
        return res;
    } catch (error) {
        return 0;
    }
};

export const get = async (id) => {
    try {
        var res = await axios.get(`/Supplier/Get?id=`+ id)
        return res;
    } catch (error) {
        return 0;
    }
};
export const getInfo = async (link, ispre) => {
    try {
        var res = await axios.get(`/Supplier/GetInfo`, {
            params: {
                link: link,
                ispre: 1
            }
        })
        return res;
    } catch (error) {
        return 0;
    }
};
export const getLink = async (link, type,id) => {
    try {
        var res = await axios.get(`/Supplier/GetLink`, {
            params: {
                link: link,
                ispre: 1,
                type: type,
                supplierId: id
            }
        })
        return res;
    } catch (error) {
        return 0;
    }
};
export const downloadStatus = async (shutterstock, type,id) => {
    try {
        var res = await axios.get(`/Supplier/DownloadStatus`, {
            params: {
                shutterstock: shutterstock,
                ispre: 1,
                type: type,
                id: id
            }
        })
        return res;
    } catch (error) {
        return 0;
    }
};
export const gettk = async () => {
    try {
        var res = await axios.get(`/Supplier/gettk`)
        return res;
    } catch (error) {
        return 0;
    }
};

