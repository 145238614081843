import axios from '../axios';



export const getCurrentInfo = async () => {
    try {
        var res = await axios.get(`/Client/GetCurrentInfo`)
        return res;
    } catch (error) {
        if (error.message == 'Request failed with status code 401') {
            return 401
        }
        else {
            return 0;
        }
    }
};
export const login = async (loginModel) => {
    try {
        var res = await axios.post(`/Client/SignIn`, loginModel)
        if (res.isSuccess) {
            if (axios.defaults.headers['Authorization']) {
                axios.defaults.headers['Authorization'] = res.data.token ? 'Bearer ' + res.data.token : "";
            }
            else {
                axios.defaults.headers = { 'Authorization': 'Bearer ' + res.data.token }
            }

            return res;
        }
        else {
            return res;
        }
    } catch (error) {
        return 0;
    }
};
export const update = async (model) => {
    try {
        var res = await axios.post(`/Client/Update`, model)
        return res;
    } catch (error) {
        return 0;
    }
};
export const create = async (model) => {
    try {
        var res = await axios.post(`/Client/Register`, model)
        return res;
    } catch (error) {
        return 0;
    }
};
export const changePassWord = async (model) => {
    try {
        var res = await axios.post(`/Client/ChangePassWord`, model)
        return res;
    } catch (error) {
        return 0;
    }
};

export const getDownloadHistory = async (filterModel) => {
    try {
        var res = await axios.get(`/Client/GetDownloadHistory`, {
            params: {
                isPromotion: filterModel.isPromotion,
                searchText: filterModel.searchText,
                pageNum: filterModel.pageNum,
                pageSize: filterModel.pageSize,
            }
        })
        return res;
    } catch (error) {
        return 0;
    }
};
export const getAddPointHistory = async (filterModel) => {
    try {
        var res = await axios.get(`/Client/GetAddPointHistory`, {
            params: {
                searchText: filterModel.searchText,
                pageNum: filterModel.pageNum,
                pageSize: filterModel.pageSize,
            }
        })
        return res;
    } catch (error) {
        return 0;
    }
};