import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from "connected-react-router";

import * as actions from "../../store/actions";
import './Login.scss';


import logo from "../../assets/images/logo.png";
import translate from "../../assets/images/icon/translate.png";
import google from "../../assets/images/icon/google.png";
import * as clientService from "../../services/clientService.js"
import * as messageService from "../../services/messageService.js"
import { ReactNotifications } from 'react-notifications-component'
import * as valid from '../../utils/validate'
import { GoogleLogin } from 'react-google-login';


const environment = process.env
const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

class Login extends Component {
    constructor(props) {
        super(props);
        this.myRef = React.createRef();
        this.state = {
            loginModel: {
                username: "",
                password: "",
                googleId: "",
                email: "",
            },
            createModel: {
                name: "",
                lastName: "",
                phoneNumber: "",
                email: "",
                companyName: "",
                address: "",
                password: "",
                googleId: ""
            },
            isShowPw: false
        }
    }
    componentDidMount() {
    }
    handlePage = (path) => {
        this.props.navigate(path);
    }

    onChangeInput = (event, id) => {
        let copyState = { ...this.state.loginModel };
        copyState[id] = event.target.value;
        this.setState({
            loginModel: copyState
        })
    }
    showHidePw = () => {
        this.setState({
            isShowPw: !this.state.isShowPw
        })
    }
    handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            this.onSubmit();
        }
    }
    onSubmit = async () => {
        if (!this.isValidData()) return;
        let copyState = { ...this.state.loginModel };
        copyState.email = this.state.loginModel.username;
        this.setState({
            loginModel: copyState
        })
        let res = await clientService.login(this.state.loginModel);
        if (res == 0) {
            messageService.messageNotify('', 'Lấy dữ liệu lỗi', 'warning');
            return;
        }
        else if (res.isSuccess) {
            this.props.userLoginSuccess(res.data);
        }
        else {
            messageService.messageNotify('', res.message, 'warning');
            return;
        }
    }
    isValidData = () => {
        if (!this.state.loginModel.username) {
            messageService.messageNotify('', 'Vui lòng nhập email', 'warning');
            return false;
        }
        else if (!valid.validateEmail(this.state.loginModel.username)) {
            messageService.messageNotify('', 'Email không đúng định dạng', 'warning');
            return false;
        }
        if (!this.state.loginModel.password) {
            messageService.messageNotify('', 'Vui lòng nhập mật khẩu', 'warning');
            return false;
        }
        return true;
    }

    render() {
        // const loginFromGoogle = (rs) => {
        //     console.log(rs);
        // }
        return (
            <>
                <ReactNotifications className='notify-login' />
                <div className="auth-page-wrapper pt-5">
                    <div className="auth-one-bg-position auth-one-bg" id="auth-particles">
                        <div className="bg-overlay"></div>

                        <div className="shape">
                            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlnsXlink='http://www.w3.org/1999/xlink' viewBox="0 0 1440 120">
                                <path d="M 0,36 C 144,53.6 432,123.2 720,124 C 1008,124.8 1296,56.8 1440,40L1440 140L0 140z"></path>
                            </svg>
                        </div>
                    </div>

                    <div className="auth-page-content">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="text-center mt-sm-5 mb-4 text-white-50">
                                        <div>
                                            <a onClick={() => this.handlePage('/')} className="d-inline-block auth-logo">
                                                <img src="assets/images/logo-light.png" alt="" height="20" />
                                            </a>
                                        </div>
                                        <p className="mt-3 fs-15 fw-medium"></p>
                                    </div>
                                </div>
                            </div>

                            <div className="row justify-content-center">
                                <div className="col-md-8 col-lg-6 col-xl-5">
                                    <div className="card mt-4">

                                        <div className="card-body p-4">
                                            <div className="text-center mt-2">
                                                <h5 className="text-primary">Chào mừng bạn trở lại !</h5>
                                                <p className="text-muted">Đăng nhập để tiếp tục sử dụng muaStocks.com</p>
                                            </div>
                                            <div className="p-2 mt-4">

                                                <div className="mb-3">
                                                    <label htmlFor="username" className="form-label">Email</label>
                                                    <input onKeyPress={this.handleKeyPress}
                                                        value={this.state.loginModel.username}
                                                        onChange={(event) => this.onChangeInput(event, "username")}
                                                        type="text" className="form-control" id="username" placeholder="Nhập email" />
                                                </div>

                                                <div className="mb-3">
                                                    {/* <div className="float-end">
                                                        <a href="auth-pass-reset-basic.html" className="text-muted">Forgot password?</a>
                                                    </div> */}
                                                    <label className="form-label" htmlFor="password-input">Mật khẩu</label>
                                                    <div className="position-relative auth-pass-inputgroup mb-3">
                                                        <input onKeyPress={this.handleKeyPress}
                                                            value={this.state.loginModel.password} id='password' name='password'
                                                            type={this.state.isShowPw ? 'text' : 'password'}
                                                            onChange={(event) => this.onChangeInput(event, "password")}
                                                            className="form-control pe-5 password-input" placeholder="Nhập mật khẩu" />
                                                        <button onClick={() => this.showHidePw()}
                                                            className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted password-addon" type="button" id="password-addon">
                                                            <i className={!this.state.isShowPw ? 'fa-eye fas fa-eye-slash align-middle' : 'far fa-eye align-middle'}></i>
                                                        </button>
                                                    </div>
                                                </div>

                                                {/* <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value="" id="auth-remember-check" />
                                                    <label className="form-check-label" htmlFor="auth-remember-check">Remember me</label>
                                                </div> */}

                                                <div className="mt-4">
                                                    <button
                                                        onClick={() => this.onSubmit()}
                                                        className="btn btn-success w-100" >Đăng nhập</button>
                                                </div>

                                                {/* <div className="mt-4 text-center">
                                                    <div className="signin-other-title">
                                                        <h5 className="fs-13 mb-4 title">Sign In with</h5>
                                                    </div>
                                                    <div className='btn-social'>
                                                        <button type="button" className="btn btn-primary btn-icon waves-effect waves-light"><i className="ri-facebook-fill fs-16"></i></button>
                                                        <button type="button" className="btn btn-danger btn-icon waves-effect waves-light"><i className="ri-google-fill fs-16"></i></button>
                                                        <button type="button" className="btn btn-dark btn-icon waves-effect waves-light"><i className="ri-github-fill fs-16"></i></button>
                                                        <button type="button" className="btn btn-info btn-icon waves-effect waves-light"><i className="ri-twitter-fill fs-16"></i></button>
                                                    </div>
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="mt-4 text-center">
                                        <p className="mb-0">Bạn chưa có tài khoản ? <a onClick={() => this.handlePage('/register')} className="fw-semibold text-primary text-decoration-underline"> Đăng ký </a> </p>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                    <footer className="footer">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="text-center">
                                        <p className="mb-0 text-muted">&copy; 2022 muastocks.com
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </footer>
                </div>
            </>

        )
    }
}

const mapStateToProps = state => {
    return {
        language: state.app.language
    };
};

const mapDispatchToProps = dispatch => {
    return {
        navigate: (path) => dispatch(push(path)),
        // userLoginFail: () => dispatch(actions.userLoginFail()),
        userLoginSuccess: (userInfo) => dispatch(actions.userLoginSuccess(userInfo))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
