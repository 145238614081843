import axios from '../axios';


export const get = async (_id) => {
    try {
        var res = await axios.get(`/Notification/Get`, {
            params: {
                id: _id
            }
        })
        return res;
    } catch (error) {
        return 0;
    }
};
