import React, { Component } from 'react';
import { connect } from 'react-redux';
import './GetLink.scss';
import { push } from "connected-react-router";

import * as userService from "../../services/userService"
import * as messageService from "../../services/messageService"
import { ReactNotifications } from 'react-notifications-component'
import * as supplierService from "../../services/supplierService"
import * as newService from "../../services/newService"
import * as constant from '../../utils/constant'
import ProgressBar from "@ramonak/react-progress-bar";
import * as actions from "../../store/actions";

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

class GetLink extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isEditPro: false,
            link: "",
            type: "",
            count: 0,
            supplier: {
            },
            process: 0,
            isProcess: false,
            user: {
                email: "",
                name: "",
                lastName: "",
                phoneNumber: "",
                address: "",
                companyName: ""
            },
            isShowDowload: false,
            isShowGetLink: true,
            linkDowload: '',
            filterNew: {
                searchText: "",
                isPromotion: true,
                pageSize: 20,
                pageNum: 1
            },
            news: [],
        }
    }
    componentDidMount() {
        this.getCurrentUser();
        this.getNews();
        // this.getCurrentSupplier();
        this.setState({
            isShowDowload: false,
            isShowGetLink: true
        })
        if (!this.props.match.params.id) {
            this.getListLink();
        }
        else {
            this.getCurrentSup()
        }
    }
    getNews = async () => {
        let res = await newService.getListNew(this.state.filterNew);
        if (res.isSuccess) {
            this.setState({
                news: res.data
            })
        }
    }
    getCurrentSup = async () => {
        let res = await supplierService.get(this.props.match.params.id);
        if (res == 0) {
            return;
        }
        else if (res.isSuccess) {
            this.setState({
                supplier: res.data
            })
        }
        else {
            return;
        }
    }
    getListLink = async () => {
        let res = await supplierService.getListSupplier();
        if (res == 0) {
            return;
        }
        else if (res.isSuccess) {
            this.setState({
                supplier: res.data[0]
            })
        }
        else {
            return;
        }
    }
    getCurrentUser = async () => {
        let res = await userService.getCurrentInfo();
        if (res == 0) {
            return;
        }
        // else if (res == 401) {
        //     setTimeout(() => {
        //         this.handlePage('/login')
        //     }, 2000);
        //     return;
        // }
        else if (res.isSuccess) {
            let copyState = { ...this.state.user };
            copyState = res.data;
            this.setState({
                user: copyState
            })
        }
        else {
            return;
        }
    }
    
    handlePage = (path) => {
        this.props.navigate(path);
    }
    onChangeInput = (event) => {
        this.setState({
            link: event.target.value.trim()
        })
    }
    reSet = () => {
        this.setState({
            isShowDowload: false,
            isShowGetLink: true,
            link: "",
            isProcess: false
        })
    }
    onSubmitLink = async () => {
        if (!this.state.user.email) {
            localStorage.removeItem("persist:user");
            window.location.href = '/login';
           // this.handlePage('/login');
        }
        else {
            if (this.state.supplier.id) {
                if (!this.isValidData()) return;
                this.setState({
                    isShowGetLink: false,
                    isProcess: true
                });
                let resInfo = await supplierService.getInfo(this.state.link);
                if (resInfo == 0) {
                    this.setState({
                        isProcess: false
                    });
                    messageService.messageNotify("", "Get link không thành công", "warning");
                    return;
                }
                else if (resInfo.isSuccess) {
                    console.log('resInfo', resInfo);
                    if (resInfo.data.result && resInfo.data.result.status == 200) {
                        this.setState({
                            process: 30
                        });
                        let type = Object.keys(resInfo.data.result.result.support.type).find(key => resInfo.data.result.result.support.type[key] == 0)
                        this.setState({
                            type: type,
                            count: 0
                        })
                        if (type) {
                            let resLink = await supplierService.getLink(this.state.link, this.state.type, this.state.supplier.id);
                            if (resLink == 0) {
                                messageService.messageNotify("", "Get link không thành công", "warning");
                                this.setState({
                                    isShowGetLink: true,
                                    process: false
                                })
                                return;
                            }
                            else if (resLink.isSuccess) {
                                if (resLink.data.status == 200) {
                                    this.setState({
                                        process: 60
                                    });
                                    this.checkStatusDowload(resLink)
                                }
                                else {
                                    messageService.messageNotify("", "Get link không thành công", "warning");
                                    this.setState({
                                        isShowGetLink: true,
                                        isProcess: false
                                    })
                                    return;
                                }
                            }
                            else {
                                messageService.messageNotify("", resLink.message, "warning");
                                this.setState({
                                    isShowGetLink: true,
                                    isProcess: false
                                })
                                return;
                            }
                        }
                    }
                    else {
                        this.setState({
                            isShowGetLink: true,
                            isProcess: false
                        })
                        messageService.messageNotify("", "Get link không thành công", "warning");
                        return;
                    }
                }
                else {
                    this.setState({
                        isShowGetLink: true,
                        isProcess: false
                    })
                    messageService.messageNotify("", resInfo.message, "warning");
                    return;
                }
            }
        }
    }
    checkStatusDowload = async (resLink) => {
        console.log('resLink2', resLink);
        let shutterstock = resLink.data.result.provSlug
        let id = resLink.data.result.itemID
        let resDownload = await supplierService.downloadStatus(shutterstock, this.state.type, id);
        if (resDownload == 0) {
            return;
        }
        else if (resDownload.isSuccess) {
            console.log('resDownload', resDownload);
            if (resDownload.data.result.status == 200) {
                if (resDownload.data.result.result.status == 1) {
                    let itemDCode = resDownload.data.result.result.itemDCode
                    let resTk = await supplierService.gettk(shutterstock, this.state.type, id);
                    if (resTk == 0) {
                        return;
                    }
                    else if (resTk.isSuccess) {
                        this.setState({
                            process: 100
                        });
                        this.setState({
                            linkDowload: 'https://getstocks.net/api/v1/download/' + itemDCode + '?token=' + resTk.data,
                            isShowDowload: true,
                            process: 100
                        })
                    }
                    else {
                        return;
                    }
                }
                else {
                    if (this.state.count < 5) {
                        this.setState({
                            process: 80 + this.state.count * 4
                        });
                        setTimeout(
                            function () {
                                this.checkStatusDowload(resLink);
                            }
                                .bind(this),
                            5000
                        );
                        this.setState({
                            count: this.state.count++
                        })

                    }
                }
            }
            else {
                if (this.state.count < 5) {
                    this.setState({
                        process: 80 + this.state.count * 4
                    });
                    setTimeout(
                        function () {
                            this.checkStatusDowload(resLink);
                        }
                            .bind(this),
                        5000
                    );
                    this.setState({
                        count: this.state.count++
                    })
                }
            }
        }
        else {
            return;
        }
    }
    dowLoadFile = async () => {
        window.open(this.state.linkDowload, "_self");
    }
    isValidData = () => {
        let isValid = false;
        if (!this.state.link) {
            messageService.messageNotify("", "Vui lòng nhập link", "warning")
            return false;
        }
        else if (this.state.link.toLocaleLowerCase().search(this.state.supplier.website.toLocaleLowerCase()) >= 0) {
            var codeList = this.state.supplier.code.toLocaleLowerCase().split('|');
            codeList.map(m => {
                if (this.state.link.toLocaleLowerCase().search(m) >= 0) {
                    isValid = true;
                }
            })
        }
        if (!isValid) messageService.messageNotify("", "Vui lòng nhập link " + this.state.supplier.name, "warning")
        return isValid;
    }
    render() {
        let isShowDowload = this.state.isShowDowload
        let isProcess = this.state.isProcess
        let listNew = this.state.news
        let path = constant.url.urlImg;
        // let isShowDowload = true
        // let supplier = this.state.supplier
        return (
            <div className="main-content">
                <ReactNotifications />
                <div className="page-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-8">
                                <div className="card">
                                    <div className="card-header align-items-center d-flex">
                                        <h4 className="card-title mb-0 flex-grow-1"
                                            style={{ color: '#6883de' }}>Getlink {this.state.supplier?.name} giá siêu rẻ chỉ {this.state.supplier?.usePoint}.000 đồng</h4>
                                    </div>
                                    <div className="card-body">
                                        <div className="live-preview">
                                            <div className="row align-items-center g-3">
                                                <div className="col-lg-12">
                                                    <div >
                                                        <label htmlFor="exampleFormControlTextarea5" className="form-label">
                                                            Dán link {this.state.supplier?.name} vào ô bên dưới và nhấn nút getlink:</label>
                                                        <textarea
                                                            value={this.state.link}
                                                            onChange={(event) => this.onChangeInput(event)}
                                                            className="form-control" id="exampleFormControlTextarea5" rows="7"></textarea>
                                                    </div>

                                                    <div className='card-body' style={{ textAlign: 'center' }}>
                                                        {this.state.isShowGetLink && <button
                                                            onClick={() => this.onSubmitLink()}
                                                            type="button" className="btn btn-outline-info waves-effect waves-light fs-22">
                                                            <i className="bx bxs-download fs-19"></i>&nbsp; GETLINK</button>}
                                                        <button
                                                            style={{ marginLeft: '15px' }}
                                                            onClick={() => this.reSet()}
                                                            type="button" className="btn btn-outline-warning waves-effect waves-light fs-22">
                                                            <i className="bx bx-reset fs-19"></i>&nbsp; RESET</button>
                                                    </div>
                                                    {isProcess && 
                                                    <ProgressBar completed={this.state.process} maxCompleted={100} />}
                                                    <div className='card-body'>
                                                        <div className="form-group">
                                                            <label>Định dạng hỗ trợ:
                                                                {this.state.supplier?.isAI && < img className="extension" src="../../assets/images/extension/AI.png" />}
                                                                {this.state.supplier?.isAIV && < img className="extension" src="../../assets/images/extension/AVI.png" />}
                                                                {this.state.supplier?.isEPS && < img className="extension" src="../../assets/images/extension/EPS.png" />}
                                                                {this.state.supplier?.isJPG && < img className="extension" src="../../assets/images/extension/JPG.png" />}
                                                                {this.state.supplier?.isMOV && < img className="extension" src="../../assets/images/extension/MOV.png" />}
                                                                {this.state.supplier?.isMP3 && < img className="extension" src="../../assets/images/extension/MP3.png" />}
                                                                {this.state.supplier?.isPNG && < img className="extension" src="../../assets/images/extension/PNG.png" />}
                                                                {this.state.supplier?.isPSD && < img className="extension" src="../../assets/images/extension/PSD.png" />}
                                                                {this.state.supplier?.isSVG && < img className="extension" src="../../assets/images/extension/SVG.png" />}
                                                                {this.state.supplier?.isTIF && < img className="extension" src="../../assets/images/extension/TIF.png" />}
                                                                {this.state.supplier?.isZIP && < img className="extension" src="../../assets/images/extension/ZIP.png" />}
                                                            </label>
                                                        </div>
                                                    </div>
                                                    {isShowDowload &&
                                                        <div className='card-body' style={{ textAlign: 'center' }}>
                                                            <button
                                                                onClick={() => this.dowLoadFile()}
                                                                type="button" className="btn btn-outline-info waves-effect waves-light fs-22">
                                                                <i className="bx bxs-download fs-19"></i>&nbsp; DOWNLOAD</button>
                                                        </div>}
                                                </div>
                                                <div className='col-md-12'>
                                                    <div className='package-poit row'>
                                                        <div className='col-md-4 box-item box'>
                                                            <h3>Gói Điểm</h3>
                                                            <p>Không giới hạn thời gian sử dụng</p>
                                                        </div>
                                                        <div className='col-md-2 box-item padding-bot-7'>
                                                            <h3>50.000đ</h3>
                                                            <p>50 điểm</p>
                                                            <p className='red'>---</p>
                                                            <div className='line'>
                                                            </div>
                                                        </div>
                                                        <div className='col-md-2 box-item padding-bot-7'>
                                                            <h3>100.000đ</h3>
                                                            <p>100 điểm</p>
                                                            <p className='red'>Bonus 3%</p>
                                                            <div className='line'>
                                                            </div>
                                                        </div>
                                                        <div className='col-md-2 box-item padding-bot-7'>
                                                            <h3>200.000đ</h3>
                                                            <p>200 điểm</p>
                                                            <p className='red'>Bonus 5%</p>
                                                            <div className='line'>
                                                            </div>
                                                        </div>
                                                        <div className='col-md-2 box-item padding-bot-7'>
                                                            <h3>500.000đ</h3>
                                                            <p>500 điểm</p>
                                                            <p className='red'>Bonus 7%</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-md-12'>
                                                    <p style={{ fontSize: '17px' }}>
                                                        <label style={{ color: '#405189', fontWeight: 'bold' }}>THÔNG TIN:</label> Khách hàng mua gói tải full 21 site, không giới hạn thời
                                                        gian sử dụng, cứ hết điểm thì nạp dùng tiếp. Trường hợp bị trừ điểm mà file không về
                                                        thì inbox admin nhờ hỗ trợ.
                                                    </p>
                                                </div>
                                                <div className='d-flex flex-wrap gap-2' style={{ textAlign: 'center' }}>
                                                    <a href='/system/payment' type="button" class="btn rounded-pill btn-success waves-effect waves-light  fs-17"
                                                        style={{ margin: "0 auto" }}>NẠP NGAY</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="card">
                                    <div className="card-header align-items-center d-flex">
                                        <h4 className="card-title mb-0 flex-grow-1"
                                            style={{ color: '#6883de' }}>Khuyến mãi đang diễn ra</h4>
                                    </div>
                                    <div className="card-body">
                                        <div className="live-preview">
                                            {listNew && listNew.map((item, index) => {
                                                return (
                                                    <a href={item.link} target='blank'>
                                                        <div className="row align-items-center g-3" style={{ marginTop: '0px' }}>
                                                            <div className="col-lg-2">
                                                                {item.imageUrl && <img
                                                                    style={{ width: '100%' }} src={path + item.imageUrl}
                                                                    className="img-thumbnail user-profile-image"
                                                                    alt="user-profile-image" />
                                                                }
                                                            </div>
                                                            <div className="col-lg-10">
                                                                {item.name}
                                                            </div>
                                                        </div>
                                                    </a>
                                                )
                                            })
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
    };
};

const mapDispatchToProps = dispatch => {
    return {
        navigate: (path) => dispatch(push(path)),
        processLogout: () => dispatch(actions.processLogout()),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(GetLink);
